import Slider from "react-slick";

import main1 from "./assets/img/main-1.png";
import main2 from "./assets/img/main-2.png";
import logo from "./assets/img/logo.png";
import footer from "./assets/img/footer.png";
import footer2 from "./assets/img/footer-2.png";
import telegram from "./assets/img/telegram.svg";
import twitter from "./assets/img/twitter.svg";
import medium from "./assets/img/medium.svg";
import prev from "./assets/img/prev.svg";
import next from "./assets/img/next.svg";

import slider1 from "./assets/img/001.png";
import slider2 from "./assets/img/002.png";
import slider3 from "./assets/img/003.png";
import slider4 from "./assets/img/004.png";
import slider5 from "./assets/img/005.png";
import slider6 from "./assets/img/006.png";
import slider7 from "./assets/img/007.png";
import slider8 from "./assets/img/008.png";
import slider9 from "./assets/img/009.png";
import slider10 from "./assets/img/010.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.css";
import { useRef } from "react";

const settings = {
  dots: false,
  infinite: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3000,
};

function App() {
  const slider = useRef(null);
  return (
    <div className="App">
      <header className="max-w-[1920px] h-[1080px] bg-[url('./assets/img/bg.png')] bg-no-repeat bg-cover">
        <ul className="flex justify-center gap-10 pt-8 flex-col px-5 md:pt-12 md:flex-row">
          <li>
            <a
              href="https://t.me/RickGPT"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#43fe2e] shadow-btn rounded-xl text-black flex items-center px-5 py-3 gap-2 text-sm"
            >
              <div className="bg-black rounded-full p-[6px]">
                <img
                  className="w-[18px] h-[18px]"
                  src={telegram}
                  alt="telegram"
                />
              </div>
              TELEGRAM
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/RickGPTETH"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#43fe2e] shadow-btn rounded-xl text-black flex items-center px-5 py-3 gap-2 text-sm"
            >
              <div className="bg-black rounded-full p-[6px]">
                <img
                  className="w-[18px] h-[18px]"
                  src={twitter}
                  alt="twitter"
                />
              </div>
              TWITTER
            </a>
          </li>
          <li>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#43fe2e] shadow-btn rounded-xl text-black flex items-center px-5 py-3 gap-2 text-sm"
            >
              <div className="bg-black rounded-full p-[6px]">
                <img className="w-[18px] h-[18px]" src={medium} alt="medium" />
              </div>
              MEDIUM
            </a>
          </li>
        </ul>
        <div className="max-w-2xl mx-auto pt-16">
          <img className="w-full buzz" src={logo} alt="logo" />
        </div>
      </header>

      <main className="container mx-auto px-3 md:px-5">
        <div className="bg-black border relative border-[#43fe2e] max-w-6xl mx-auto mt-[-550px] min-h-[700px] rounded-xl p-3 md:p-5 lg:p-12 lg:rounded-3xl">
          <Slider {...settings} ref={slider} className="pt-5 md:pt-10">
            <div>
              <div>
                <h2 className="text-center text-xl uppercase md:text-3xl">
                  <span className="text-[#43fe2e]">Rick Sanchez</span> is a
                  complex and enigmatic character from the animated series{" "}
                  <span className="text-[#43fe2e]">"Rick and Morty"</span>
                </h2>
                <div className="flex flex-col-reverse pt-8 gap-10 lg:flex-row">
                  <div className="w-full lg:max-w-xl">
                    <h4 className="text-xl">
                      <span className="text-[#43fe2e]">#001:</span> Original
                      rick
                    </h4>
                    <p className="font-thin pt-5">
                      He is a brilliant and eccentric scientist who possesses an
                      unmatched intellect and scientific prowess. With his
                      trademark cynical humor and unapologetic demeanor, Rick
                      navigates a multiverse of infinite possibilities,
                      seamlessly hopping across dimensions with his trusty
                      portal gun.
                      <br />
                      <br />
                      Beneath his abrasive and carefree exterior, Rick harbors a
                      deep emotional complexity, grappling with existential
                      questions while often resorting to alcohol as a coping
                      mechanism.
                      <br />
                      <br />
                      Despite his flaws, Rick's ingenious inventions, unmatched
                      problem-solving skills, and unpredictable antics make him
                      an unforgettable and intriguing character in the
                      ever-twisting cosmic tapestry of "Rick and Morty."
                    </p>
                  </div>
                  <div>
                    <img
                      className="rounded-xl h-[300px] mx-auto"
                      src={slider1}
                      alt="slider"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-16">
                <h2 className="text-center text-xl uppercase md:text-3xl">
                  Many versions of{" "}
                  <span className="text-[#43fe2e]">Rick Sanchez</span> from
                  different{" "}
                  <span className="text-[#43fe2e]">
                    dimensions and realities
                  </span>
                </h2>
                <div className="pt-8 flex gap-10 flex-col lg:flex-row">
                  <div>
                    <img
                      className="rounded-xl h-[300px] mx-auto"
                      src={slider2}
                      alt="slider"
                    />
                  </div>
                  <div className="w-full lg:max-w-xl">
                    <h4 className="text-xl">
                      <span className="text-[#43fe2e]">#002:</span>Rick Prime
                    </h4>
                    <p className="font-thin pt-5">
                      Rick Prime has more grayish skin than other versions of
                      Ricks, as well as more dull-colored hair in a
                      "nondescript" haircut. He wore a purplish "sci-fi" jacket
                      with a gray shoulder pad and a red shirt underneath. He
                      also wore grayish black pants and appears to have been
                      using the "iPhone 3 equivalent" prototype of the modern
                      Portal Gun upon first meeting Rick C-137.
                      <br />
                      <br />
                      After many years, his appearance is completely unchanged,
                      implying that he either actually is a clone or his healing
                      abilities also give him longevity.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <h2 className="text-center text-xl uppercase md:text-3xl">
                  <span className="text-[#43fe2e]">Rick Sanchez</span> is a
                  complex and enigmatic character from the animated series{" "}
                  <span className="text-[#43fe2e]">"Rick and Morty"</span>
                </h2>
                <div className="flex pt-8 gap-10 flex-col-reverse lg:flex-row">
                  <div className="w-full lg:max-w-xl">
                    <h4 className="text-xl">
                      <span className="text-[#43fe2e]">#003:</span> Original
                      rick
                    </h4>
                    <p className="font-thin pt-5">
                      Alien Rick is an alien old man. He has slimy lime-green
                      skin and antennae on his head. He has light blue spiky
                      hair and a unibrow. He has three eyes, with one of them on
                      his forehead, a big nose, elf-like pointed ears and four
                      arms. He wears a light blue shirts and a white lab coat
                      with extra sleeves for his two additional arms.
                    </p>
                  </div>
                  <div>
                    <img
                      className="rounded-xl h-[300px] mx-auto"
                      src={slider3}
                      alt="slider"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-16">
                <h2 className="text-center text-xl uppercase md:text-3xl">
                  Many versions of{" "}
                  <span className="text-[#43fe2e]">Rick Sanchez</span> from
                  different{" "}
                  <span className="text-[#43fe2e]">
                    dimensions and realities
                  </span>
                </h2>
                <div className="pt-8 flex gap-10 flex-col lg:flex-row">
                  <div>
                    <img
                      className="rounded-xl h-[300px] mx-auto"
                      src={slider4}
                      alt="slider"
                    />
                  </div>
                  <div className="w-full lg:max-w-xl">
                    <h4 className="text-xl">
                      <span className="text-[#43fe2e]">#004:</span> Afro Rick
                    </h4>
                    <p className="font-thin pt-5">
                      Afro Rick is an alternate version of Rick Sanchez with an
                      afro, and of African-American descent.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <h2 className="text-center text-xl uppercase md:text-3xl">
                  <span className="text-[#43fe2e]">Rick Sanchez</span> is a
                  complex and enigmatic character from the animated series{" "}
                  <span className="text-[#43fe2e]">"Rick and Morty"</span>
                </h2>
                <div className="flex pt-8 gap-10 flex-col-reverse lg:flex-row">
                  <div className="w-full lg:max-w-xl">
                    <h4 className="text-xl">
                      <span className="text-[#43fe2e]">#005:</span> Pickle Rick
                    </h4>
                    <p className="font-thin pt-5">
                      "In this form, Rick's only functional body parts are his
                      brain, ears, eyes and a mouth. In all other regards, he
                      became an ordinary pickle. He is vulnerable to dehydration
                      and can be damaged (Although replacing a lost portion with
                      an ordinary pickle just left a scar when reverting back to
                      human form).
                      <br />
                      <br />
                      Changing back to human is only possible with the help of
                      an Anti-Pickle Serum."
                      <br />
                      <br />
                      Despite his flaws, Rick's ingenious inventions, unmatched
                      problem-solving skills, and unpredictable antics make him
                      an unforgettable and intriguing character in the
                      ever-twisting cosmic tapestry of "Rick and Morty."
                    </p>
                  </div>
                  <div>
                    <img
                      className="rounded-xl h-[300px] mx-auto"
                      src={slider5}
                      alt="slider"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-16">
                <h2 className="text-center text-xl uppercase md:text-3xl">
                  Many versions of{" "}
                  <span className="text-[#43fe2e]">Rick Sanchez</span> from
                  different{" "}
                  <span className="text-[#43fe2e]">
                    dimensions and realities
                  </span>
                </h2>
                <div className="pt-8 flex gap-10 flex-col lg:flex-row">
                  <div>
                    <img
                      className="rounded-xl h-[300px] mx-auto"
                      src={slider6}
                      alt="slider"
                    />
                  </div>
                  <div className="w-full lg:max-w-xl">
                    <h4 className="text-xl">
                      <span className="text-[#43fe2e]">#006:</span> Evil Rick
                    </h4>
                    <p className="font-thin pt-5">
                      "Evil Rick Sanchez" looked identical to most Ricks, except
                      that his shirt was black instead of light blue, his eyes
                      had dark circles underneath, and his lips had a scar going
                      across it.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <h2 className="text-center text-xl uppercase md:text-3xl">
                  <span className="text-[#43fe2e]">Rick Sanchez</span> is a
                  complex and enigmatic character from the animated series{" "}
                  <span className="text-[#43fe2e]">"Rick and Morty"</span>
                </h2>
                <div className="flex pt-8 gap-10 flex-col-reverse lg:flex-row">
                  <div className="w-full lg:max-w-xl">
                    <h4 className="text-xl">
                      <span className="text-[#43fe2e]">#007:</span> Tiny Rick
                    </h4>
                    <p className="font-thin pt-5">
                      Tiny Rick is initially similar to older Rick, albeit more
                      optimistic and cheerful. His down-to-earth style impresses
                      the other students of the high school and he becomes very
                      popular. Over time, however, the teenaged brain, who does
                      not want the older consciousness to transfer back into his
                      original body, starts to dominate Tiny Rick’s personality.
                      Rick’s older consciousness fights against the younger
                      brain by turning his cries for help into expressions of
                      creativity
                    </p>
                  </div>
                  <div>
                    <img
                      className="rounded-xl h-[300px] mx-auto"
                      src={slider7}
                      alt="slider"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-16">
                <h2 className="text-center text-xl uppercase md:text-3xl">
                  Many versions of{" "}
                  <span className="text-[#43fe2e]">Rick Sanchez</span> from
                  different{" "}
                  <span className="text-[#43fe2e]">
                    dimensions and realities
                  </span>
                </h2>
                <div className="pt-8 flex gap-10 flex-col lg:flex-row">
                  <div>
                    <img
                      className="rounded-xl h-[300px] mx-auto"
                      src={slider8}
                      alt="slider"
                    />
                  </div>
                  <div className="w-full lg:max-w-xl">
                    <h4 className="text-xl">
                      <span className="text-[#43fe2e]">#008:</span> Cronenberg
                      Rick
                    </h4>
                    <p className="font-thin pt-5">
                      Cronenberg Rick is a version of Rick Sanchez who
                      originates from Cronenberg World. He and his grandson
                      moved to the Cronenberged dimension after everyone there
                      were turned into Cronenbergs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <h2 className="text-center text-xl uppercase md:text-3xl">
                  <span className="text-[#43fe2e]">Rick Sanchez</span> is a
                  complex and enigmatic character from the animated series{" "}
                  <span className="text-[#43fe2e]">"Rick and Morty"</span>
                </h2>
                <div className="flex pt-8 gap-10 flex-col-reverse lg:flex-row">
                  <div className="w-full lg:max-w-xl">
                    <h4 className="text-xl">
                      <span className="text-[#43fe2e]">#009:</span> Doofus Rick
                    </h4>
                    <p className="font-thin pt-5">
                      Doofus Rick is the kindest and sweetest of all the Ricks.
                      He approached Jerry during the investigation at the Smith
                      house and engaged him in conversation, expressing genuine
                      interest in Jerry's life and abilities; perhaps because
                      they were both ostracized and mocked by the rest of the
                      Ricks present, the two quickly bonded.
                    </p>
                  </div>
                  <div>
                    <img
                      className="rounded-xl h-[300px] mx-auto"
                      src={slider9}
                      alt="slider"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-16">
                <h2 className="text-center text-xl uppercase md:text-3xl">
                  Many versions of{" "}
                  <span className="text-[#43fe2e]">Rick Sanchez</span> from
                  different{" "}
                  <span className="text-[#43fe2e]">
                    dimensions and realities
                  </span>
                </h2>
                <div className="pt-8 flex gap-10 flex-col lg:flex-row">
                  <div>
                    <img
                      className="rounded-xl h-[300px] mx-auto"
                      src={slider10}
                      alt="slider"
                    />
                  </div>
                  <div className="w-full lg:max-w-xl">
                    <h4 className="text-xl">
                      <span className="text-[#43fe2e]">#010:</span>Memory Rick
                    </h4>
                    <p className="font-thin pt-5">
                      "His personality is based on Birdperson's memories of him
                      meaning his own memories prior meeting Birdperson are
                      ""generic and bird themed"". His personality varies
                      significantly to his older counterpart being more positive
                      and helpful. He is excited by Star Wars battles, loves the
                      idea of his own Vietnam and disgusted at the state of his
                      future self. He is described by real Rick as an
                      ""idealistic hipster douche"".
                      <br />
                      <br />
                      He is similar to his older self in that he is incredibly
                      intelligent. He was able to find a way to continue his
                      existence by transferring to his older self. He also
                      shares his counterparts indulges, enjoying getting high
                      and having fun."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
          <div className="flex justify-center gap-5 pt-10">
            <button
              className="bg-[#111] h-[30px] w-[30px] rounded-full inline-flex items-center justify-center"
              onClick={() => slider?.current?.slickPrev()}
            >
              <img className="h-[18px] w-[18px]" src={prev} alt="prev-btn" />
            </button>
            <button
              className="bg-[#111] h-[30px] w-[30px] rounded-full inline-flex items-center justify-center"
              onClick={() => slider?.current?.slickNext()}
            >
              <img className="h-[18px] w-[18px]" src={next} alt="next-btn" />
            </button>
          </div>
          <div className="hidden absolute max-w-[220px] top-[-150px] right-[-100px] md:block">
            <img src={main1} alt="main-1" />
          </div>
          <div className="hidden absolute max-w-[160px] bottom-[-80px] left-[-80px] md:block">
            <img src={main2} alt="main-2" />
          </div>
        </div>
      </main>

      <footer className="container mx-auto px-3 pt-[150px] pb-12 md:px-5">
        <div className="max-w-6xl mx-auto flex items-center gap-8 flex-col lg:flex-row lg:justify-between lg:gap-12">
          <div className="max-w-[300px]">
            <img className="w-full" src={logo} alt="logo" />
          </div>
          <span className="hidden w-[2px] h-[80px] bg-[#43fe2e] lg:block"></span>
          <div>
            <h4 className="text-center">Follow us for more!</h4>
            <ul className="flex gap-8 mt-5 flex-col md:flex-row">
              <li>
                <a
                  href="https://t.me/RickGPT"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#43fe2e] shadow-btn rounded-xl text-black flex items-center px-3 py-3 gap-2 text-sm"
                >
                  <div className="bg-black rounded-full p-[6px]">
                    <img
                      className="w-[13px] h-[13px]"
                      src={telegram}
                      alt="telegram"
                    />
                  </div>
                  TELEGRAM
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/RickGPTETH"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#43fe2e] shadow-btn rounded-xl text-black flex items-center px-3 py-3 gap-2 text-sm"
                >
                  <div className="bg-black rounded-full p-[6px]">
                    <img
                      className="w-[13px] h-[13px]"
                      src={twitter}
                      alt="twitter"
                    />
                  </div>
                  TWITTER
                </a>
              </li>
              <li>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-[#43fe2e] shadow-btn rounded-xl text-black flex items-center px-3 py-3 gap-2 text-sm"
                >
                  <div className="bg-black rounded-full p-[6px]">
                    <img
                      className="w-[13px] h-[13px]"
                      src={medium}
                      alt="medium"
                    />
                  </div>
                  MEDIUM
                </a>
              </li>
            </ul>
          </div>
        </div>
        <p className="text-center pt-[100px] text-sm font-thin">
          Copyright @ 2023 by rickgpt.xyz
        </p>
      </footer>

      <div className="pt-10 hidden sm:block">
        <div className="relative">
          <img src={footer} alt="footer" />
          <img
            className="max-w-xs absolute right-0 top-[-100px]"
            src={footer2}
            alt="footer-2"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
